/* Quitar el borde rojo en campos requeridos */
.sv-question--error {
  border: none !important;
}

/* Aplicar un estilo personalizado cuando un campo es requerido pero no completado */
.custom-required {
  border: 1px solid #ffcc00; /* Amarillo */
  background-color: #fff8e1; /* Fondo amarillo claro */
}

/* Mensaje de error personalizado */
.sv-question__erbox {
  color: #ffcc00 !important; /* Amarillo */
  font-style: italic;
}

/* Ocultar el mensaje "response required" */
.sd-error {
  display: none !important;
}

.sd-element--with-frame.sd-question--error-top {
   padding-top: 32px; 
}

.sd-body.sd-body--static{
  padding-top: 10px; 
}

/* En tu archivo CustomSurvey.css */
.sv-components-row {
  max-height: 600px; 
  overflow-y: auto; 
  margin-top: 6px; 
}